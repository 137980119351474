module.exports.routeNames = [
  { locale: 'cs', path: 'o-terapii' },
  { locale: 'pl', path: 'o-terapii' },
  { locale: 'uk', path: '%D0%BF%D1%80%D0%BE-%D1%82%D0%B5%D1%80%D0%B0%D0%BF%D1%96%D1%8E' }, // про-терапію
  { locale: 'hu', path: 'konzultaciorol-es-terapiarol' },
  { locale: 'sl', path: 'o-terapiji' },
  { locale: 'lt', path: 'apie-terapija' },
  { locale: 'ro', path: 'despre-terapie' },
  {
    locale: 'el',
    path: '%CF%83%CF%87%CE%B5%CF%84%CE%B9%CE%BA%CE%B1-%CE%BC%CE%B5-%CF%84%CE%B7%CE%BD-%CF%88%CF%85%CF%87%CE%BF%CE%B8%CE%B5%CF%81%CE%B1%CF%80%CE%B5%CE%AF%CE%B1',
  }, // σχετικα-με-την-ψυχοθεραπεία
  { locale: 'sk', path: 'o-terapii' },
  { locale: 'en', path: 'about-therapy' },
]
