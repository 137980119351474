'use client'

import { ButtonProps, RadixButton } from '@/src/rebrand/buttonRadix'
import React from 'react'

type Props = { children: React.ReactNode } & ButtonProps

const openChat = () => {
  window?.fcWidget?.open()
}

// this is a very generic button that opens the chat
// it should be modified through props and not through the component itself
const FreshdeskOpenButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <RadixButton onClick={openChat} {...props}>
      {children}
    </RadixButton>
  )
}

export default FreshdeskOpenButton
