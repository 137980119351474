module.exports.routeNames = [
  { locale: 'cs', path: 'temata' },
  { locale: 'pl', path: 'tematy' },
  { locale: 'uk', path: '%D1%82%D0%B5%D0%BC%D0%B8' }, // теми
  { locale: 'hu', path: 'temakorok' },
  { locale: 'sl', path: 'teme' },
  { locale: 'lt', path: 'temos' },
  { locale: 'ro', path: 'subiecte' },
  { locale: 'el', path: '%CE%B8%CE%AD%CE%BC%CE%B1%CF%84%CE%B1' }, // θέματα
  { locale: 'sk', path: 'temy' },
  { locale: 'en', path: 'topics' },
]
