import localFont from 'next/font/local'

const reckless = localFont({
  src: [
    { path: '../../public/fonts/Reckless-Regular.woff2', style: 'normal', weight: '400' },
    { path: '../../public/fonts/Reckless-Medium.woff2', style: 'normal', weight: '500' },
    { path: '../../public/fonts/Reckless-SemiBold.woff2', style: 'normal', weight: '600' },
  ],
  variable: '--font-serif',
})

export default reckless.variable
