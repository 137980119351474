import { cn } from '@/src/rebrand/utilities'

type Props = { children: React.ReactNode; color?: string; className?: string }

const EllipsisIcon: React.FC<Props> = ({ children, color, className = '' }) => {
  return (
    <svg
      viewBox="0 0 64 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('h-[74px] w-[64px]', className)}
    >
      <path
        id="Vector"
        d="M63.6744 37.0242C63.6744 16.0143 53.5727 -0.000244141 31.5021 -0.000244141C12.3519 -0.000244141 0 14.3982 0 37.3671C0 61.2175 12.3519 73.9998 31.5021 73.9998C52.8546 73.9998 63.6744 57.4954 63.6744 37.0242Z"
        fill={color}
      />

      <g transform="translate(17, 22)">{children}</g>
    </svg>
  )
}

export default EllipsisIcon
