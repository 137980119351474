import { NavigationMenuLink } from '@/src/components/header/navigationRadix'
import React from 'react'
import Image from 'next/image'
import { getTranslate } from '@/src/utilities/i18n'
import Icon from '@/src/components/icons/Icon'
import EllipsisIcon from '@/src/components/header/EllipsisIcon'
import colors from '../../rebrand/colors.js'
import { ServiceType } from '../../types/GeneralStateType'
import { SERVICES_TYPE } from '@/src/redux/DiagnosisRedux'
import { PUBLIC_THERAPISTS_PAGE_SLUG } from '@/src/constants/locale.constants'
import { PSYCHIATRY_INTRO_SERVICE_ID } from '@/src/constants/service.constants'

const bg = [colors.yellow[500], colors.blue[500], colors.pink[500]]

const NavTitle: React.FC<{ text: string }> = ({ text }) => {
  return <h3 className="text-xl font-semibold leading-relaxed text-secondary ">{text}</h3>
}

const NavLink: React.FC<{ service: ServiceType; bgColor: string }> = ({ service, bgColor }) => {
  const href =
    PSYCHIATRY_INTRO_SERVICE_ID && PSYCHIATRY_INTRO_SERVICE_ID === service.id
      ? '/psychiatrie'
      : `/${PUBLIC_THERAPISTS_PAGE_SLUG}?service=${service.id}`

  return (
    <NavigationMenuLink
      href={href}
      className="text-md !hover:text-primary group mt-5 flex items-center font-bold leading-snug text-secondary hover:no-underline laptop:mt-8 laptop:text-lg"
    >
      <EllipsisIcon color={bgColor} className="mr-4 shrink-0">
        <Icon
          icon={`${service.iconName && service.iconName.length > 0 ? `${service.iconName}-rebrand` : 'user-rebrand'}`}
          size="md"
          width={30}
          height={30}
        />
      </EllipsisIcon>
      <div className="laptop:hover:text-primary">
        <div>{service.name}</div>
        {service.description && (
          <div className="text-teal-900 text-sm font-normal leading-tight tracking-tight">{service.description}</div>
        )}
      </div>
    </NavigationMenuLink>
  )
}

const HeaderTherapistsContent: React.FC<{ services: ServiceType[] }> = ({ services }) => {
  const { t } = getTranslate()
  const links =
    services?.length &&
    services.filter(
      ({ type, visible }) => type !== SERVICES_TYPE.HIDDEN && type !== SERVICES_TYPE.COUNSELLING && visible,
    )

  return (
    <div className="relative">
      <div className="flex items-center laptop:flex-nowrap">
        <Image
          priority
          sizes="368px"
          height={330}
          width={368}
          alt={`therapists`}
          src={'/images/header/menu-content-photo2.png'}
          className="mr-12 hidden rounded-[40px] laptop:block"
        />

        <div className="mb-15">
          <NavTitle text={t('component.navigation.chooseService')} />
          <div className="grid grid-cols-1 laptop:grid-cols-2 laptop:gap-x-8 laptop:gap-y-0">
            {(links || []).map((service, index) => {
              const bgColor = bg[index % bg.length]
              return <NavLink key={`${service.id}-${index}`} service={service} bgColor={bgColor} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTherapistsContent
