import { createActions, createReducer } from 'reduxsauce'
import thunk from 'redux-thunk'
import { assocPath, mergeDeepRight } from 'ramda'
import FetchingMiddleware from '../services/FetchingMiddleware'
import { mergeValuesInArray } from '../utilities/reduxHelpers'
import type { DiagnosisStateType } from '../types/DiagnosisStateType'

export const SERVICES_TYPE = {
  MAIN: 1,
  MINOR: 2,
  HIDDEN: 3,
  INSTANT: 4,
  COUNSELLING: 5,
}

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  onChangeDiagnosisValue: ['path', 'value'],
  onMergeDiagnosisValue: ['value'],
  mergeDiagnosisValuesInArray: ['path', 'value'],
  sendDiagnosisTestRequest: ['diagnosis', 'isTherapist'],
  getDiagnosisRequest: ['diagnosis', 'isTherapist'],
})

// ======================================================
// Middleware Configuration
// ======================================================
const middleware = [thunk]

middleware.push(FetchingMiddleware as any)

export const DiagnosisTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  diagnosisFinished: false,
  lead: {
    target: 0,
    structure: 0,
    problems: 0,
    homework: 0,
    leadership: 0,
  },
  emotions: {
    selfRevelation: 0,
    relationShip: 0,
    encourage: 0,
    feelings: 0,
  },
  past: {
    myPast: 0,
    childhood: 0,
    future: 0,
  },
  personality: {
    character: 0,
    support: 0,
    consideration: 0,
    opinions: 0,
    behavior: 0,
  },
  shortDiagnosisV2: {
    lead: 0,
    emotions: 0,
    past: 0,
    personality: 0,
  },
  specialization: [],
  goal: 0,
  sex: undefined,
}

/* ------------- Reducers ------------- */

export const onChangeDiagnosisValueR = (
  state: DiagnosisStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): DiagnosisStateType => assocPath(path.split('.'), value, state)

export const onMergeDiagnosisValueR = (
  state: DiagnosisStateType,
  { value }: { value: Object | boolean | number | string },
): DiagnosisStateType => mergeDeepRight(state, value)

export const mergeDiagnosisValuesInArrayR = (
  state: DiagnosisStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): DiagnosisStateType => mergeValuesInArray(state, path, value)

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_DIAGNOSIS_VALUE]: onChangeDiagnosisValueR,
  [Types.ON_MERGE_DIAGNOSIS_VALUE]: onMergeDiagnosisValueR,
  [Types.MERGE_DIAGNOSIS_VALUES_IN_ARRAY]: mergeDiagnosisValuesInArrayR,
  [Types.SEND_DIAGNOSIS_TEST_REQUEST]: (state) => state,
  [Types.GET_DIAGNOSIS_REQUEST]: (state) => state,
})
