import { ApolloClient, InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { GRAPHQL_ENDPOINT } from './apollo.constants'

const isClient = typeof window !== 'undefined'

const cacheOptions: InMemoryCacheConfig = { typePolicies: { UploadFile: { keyFields: ['url'] } } }

const apolloClientCache = new InMemoryCache(cacheOptions)

const client = new ApolloClient({
  ssrMode: isClient ? false : true,
  uri: GRAPHQL_ENDPOINT,
  cache: isClient ? apolloClientCache.restore(window.__APOLLO_STATE__) : apolloClientCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

export default client
