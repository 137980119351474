import { NavigationMenuLink } from '@/src/components/header/navigationRadix'
import React from 'react'
import Image from 'next/image'
import Icon from '@/src/components/icons/Icon'
import { MenuType } from '@/src/utilities/apiUtils'
import { getHrefFromStrapiMenuItem } from '@/src/utilities/strapi'

const NavTitle: React.FC<{ text: string }> = ({ text }) => {
  return <h3 className="text-xl font-semibold leading-relaxed text-secondary ">{text}</h3>
}

const NavLink: React.FC<{ link: { link: string; title: string } }> = ({ link }) => {
  return (
    <NavigationMenuLink
      href={link.link}
      className="text-md mt-5 flex items-center font-semibold leading-snug text-secondary laptop:mt-8 laptop:text-base"
    >
      <Icon icon="select-arrow" size="xs" color="primary" className="mr-3 -rotate-90" />
      {link.title}
    </NavigationMenuLink>
  )
}

const HeaderOtherContent: React.FC<{ otherMenu: MenuType[] }> = ({ otherMenu }) => {
  return (
    <div className="relative">
      <div className="flex flex-nowrap items-center">
        <Image
          priority
          sizes="368px"
          height={330}
          width={368}
          alt={`therapists`}
          src={'/images/header/menu-content-photo.png'}
          className="mr-12 hidden rounded-[40px] laptop:block"
        />
        <div className="grid w-full grid-cols-1 gap-y-3 laptop:grid-cols-3 laptop:gap-x-8 laptop:gap-y-0">
          {otherMenu.map((topLevel) => (
            <div className="rounded-2xl bg-yellow-500 p-5 laptop:bg-white laptop:p-0" key={topLevel.id}>
              <NavTitle text={topLevel.attributes.title} />
              {topLevel.attributes.children?.data.map((link, index) => (
                <NavLink
                  key={`${index}-${link.id}`}
                  link={{ title: link.attributes.title, link: getHrefFromStrapiMenuItem(link) }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HeaderOtherContent
