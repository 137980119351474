import React from 'react'
import Icon from '../icons/Icon'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/src/rebrand/dropdownMenuRadix'
import { cn } from '@/src/rebrand/utilities'
import Image from 'next/image'
import { locales } from '@/src/constants/locale.constants'
import { getLocaleDomain, getLocaleDisplayName } from '@/src/utilities/languagesConfig'
import { LanguagePickerProps } from './LanguagePicker'

type ImageForLanguageProps = {
  language: {
    text: string
    domain: string
  }
}

export const ImageForLanguage: React.FC<ImageForLanguageProps> = ({ language }) => {
  return (
    <Image
      key={language.text}
      src={`/images/flags/flag.${language.domain}.svg`}
      alt={language.text}
      width={22}
      height={22}
      priority
      className="mr-4"
    />
  )
}

const LanguagePickerDropdown: React.FC<LanguagePickerProps & { children: React.ReactNode }> = ({
  align,
  className,
  children,
  theme,
}) => {
  const languagesList = locales
  const isDarkTheme = theme === 'dark'

  return (
    <div className={cn('relative', className)}>
      <div className="hidden">
        {/* the flag images are here so they are preloaded, since the dropdown is not in dom yet */}
        {languagesList.map((locale) => {
          const text = getLocaleDisplayName(locale)
          const domain = getLocaleDomain(locale) || 'com'
          return <ImageForLanguage key={`lang-${locale}`} language={{ text, domain }} />
        })}
      </div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              `rounded-2xl border-2 border-solid border-petrol-400 hover:border-peach-400 focus:border-peach-400`,
              `${isDarkTheme ? 'text-primary' : ''} hover:text-primary focus:text-primary`,
            )}
          >
            <Icon icon="internet" className="m-[9px]" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="rounded-xl bg-white py-2 shadow-rebrand" align={align}>
          {children}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default LanguagePickerDropdown
