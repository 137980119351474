'use client'

import Markdown from 'markdown-to-jsx'
import React, { useEffect, useState } from 'react'
import Icon from '../icons/Icon'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import { getHashFromString } from '@/src/utilities/codingPublicHash'
import Cookies from 'js-cookie'
import { fetchPromoBarFromStrapi } from '@/src/utilities/apiUtils'
import { usePathname } from 'next/navigation'
import { cn } from '@/src/rebrand/utilities'

type PromotionsBar = {
  attributes: {
    promotionsBar: {
      content: string
      isVisible: boolean
    }
  }
}

const PromotionsBar: React.FC = () => {
  const [promoText, setPromoText] = useState(' ')
  const [isVisible, setIsVisible] = useState(false)
  const pathname = usePathname()

  const isChatView = pathname.includes('/messages') || pathname.includes('/counselling')

  useEffect(() => {
    void (async () => {
      const data: PromotionsBar = await fetchPromoBarFromStrapi()
      if (data?.attributes?.promotionsBar?.content) setPromoText(data?.attributes?.promotionsBar?.content)
      setIsVisible(data?.attributes?.promotionsBar?.isVisible)
    })()
  }, [])

  const promoHash = 'hidePromo' + getHashFromString(promoText)

  const isPromoHiddenInCookie = Cookies.get(promoHash)

  const hidePromoBar = () => {
    Cookies.set(promoHash, 'true')
    window.location.reload()
  }

  return (
    <>
      {isPromoHiddenInCookie !== 'true' && isVisible && promoText && (
        <div
          className={cn(
            'w-full items-center justify-between gap-2 bg-blue-500 p-3 text-center text-base font-semibold',
            isChatView ? 'hidden tablet:flex' : 'flex',
          )}
        >
          <div className="w-[22px]">&nbsp;</div>
          <Markdown
            options={{
              overrides: {
                strong: {
                  props: {
                    className: 'text-peach-500 font-bold',
                  },
                },
              },
            }}
          >
            {promoText}
          </Markdown>
          <RadixButton onClick={hidePromoBar} variant="inline" className="-m-3">
            <Icon icon="cross" color="primary" size="sm" className="m-3" />
          </RadixButton>
        </div>
      )}
    </>
  )
}

export default PromotionsBar
