import isCountry from '@/src/utilities/isCountry'

export const initFreshdesk = () => {
  const widget = window?.fcWidget

  const token = process.env.NEXT_PUBLIC_FRESHDESK_TOKEN
  const host = process.env.NEXT_PUBLIC_FRESHDESK_HOST

  if (!token || !host || isCountry('it')) return

  if (!widget?.isInitialized()) {
    widget?.init({
      token,
      host,
    })
  }
}
