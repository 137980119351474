module.exports.routeNames = [
  { locale: 'cs', path: 'o-dusevni-pohode' },
  { locale: 'pl', path: 'o-zdrowiu-psychicznym' },
  {
    locale: 'uk',
    path: '%D0%BF%D1%80%D0%BE-%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5',
  }, // про-ментальне
  { locale: 'hu', path: 'a-mentalis-jolletol' },
  { locale: 'sl', path: 'o-dusevnem-zdravju' },
  { locale: 'lt', path: 'apie-psichikos-gerove' },
  { locale: 'ro', path: 'despre-wellbeing-mintal' },
  {
    locale: 'el',
    path: '%CF%83%CF%87%CE%B5%CF%84%CE%B9%CE%BA%CE%B1-%CE%BC%CE%B5-%CF%84%CE%B7%CE%BD-%CF%88%CF%85%CF%87%CE%B9%CE%BA%CE%AE-%CF%85%CE%B3%CE%B5%CE%AF%CE%B1',
  }, // σχετικα-με-την-ψυχική-υγεία
  { locale: 'sk', path: 'o-dusevnej-pohode-a-wellbeingu' },
  { locale: 'en', path: 'about-mental-wellbeing' },
]
