import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn, isNewBrandingActive } from './utilities'

const containerVariants = cva('px-5 mx-auto w-full', {
  variants: {
    width: {
      sm: 'max-w-[720px]',
      md: 'max-w-[970px]',
      lg: isNewBrandingActive ? 'max-w-[1180px]' : 'container',
      xl: 'max-w-[1300px]',
    },
  },
  defaultVariants: { width: 'lg' },
})

type Props = VariantProps<typeof containerVariants> & { className?: string; children: React.ReactNode }

const Container: React.FC<Props> = ({ width, children, className }) => {
  return <div className={cn(containerVariants({ width, className }))}>{children}</div>
}

export default Container
