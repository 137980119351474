const OBSCURE_CONSTANT = 123

export const getHashFromString = (slug: string) => {
  let result = ''
  for (let i = 0; i < slug.length; i++) {
    result += String.fromCharCode(slug.charCodeAt(i) ^ OBSCURE_CONSTANT)
  }
  let base64 = Buffer.from(result).toString('base64')
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export const getStringFromHash = (hash: string) => {
  let base64 = hash.replace(/-/g, '+').replace(/_/g, '/')
  while (base64.length % 4) {
    base64 += '='
  }
  const decoded = Buffer.from(base64, 'base64').toString('utf-8')
  let result = ''
  for (let i = 0; i < decoded.length; i++) {
    result += String.fromCharCode(decoded.charCodeAt(i) ^ OBSCURE_CONSTANT)
  }
  return result
}
