import { assoc, find, pathOr, propEq } from 'ramda'
import type { TherapistStateType } from '../types/TherapistStateType'

export const rewriteTherapistCredentials = (therapist: TherapistStateType, serviceId?: number): TherapistStateType => {
  const certificates = therapist.certificates
  if (certificates) {
    therapist = assoc(
      'certificates',
      typeof certificates === 'object' ? certificates : certificates.split('\n'),
      therapist,
    )
  }
  if (serviceId && Array.isArray(therapist.prices)) {
    therapist = assoc('price', pathOr(0, ['price'], find(propEq(serviceId, 'serviceId'))(therapist.prices)), therapist)
  }
  return therapist
}

export default { rewriteTherapistCredentials }
