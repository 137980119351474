'use client'

import { useEffect } from 'react'
import { initFreshdesk } from './freshdesk.utils'
import Script from 'next/script'

export default function FreshdeskWidget() {
  useEffect(() => {
    if (!window?.fcWidget?.isInitialized()) {
      console.log('freshDesk - init (from widget)')
      initFreshdesk()
    } else {
      console.log('freshDesk - show')
      window?.fcWidget?.show()
    }

    return () => {
      console.log('freshDesk - hide')
      window?.fcWidget?.hide()
    }
  }, [])

  return (
    <Script
      src="https://wchat.freshchat.com/js/widget.js"
      onLoad={() => {
        console.log('freshDesk - init (from script)')
        initFreshdesk()
      }}
    />
  )
  //   return null
}
