import { Inter } from 'next/font/google'
import currentSerifVariable from './fontSerifCurrent'

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap',
})

export const fontSansVariable = inter.variable

export const fontSerifVariable = currentSerifVariable

export const rebrandFontVariables = `${fontSansVariable} ${fontSerifVariable}`
