'use client'

import { getTranslate } from '@/src/utilities/i18n'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import { isNewBrandingActive } from '@/src/rebrand/utilities'

const FooterInformation: React.FC = () => {
  const { t } = getTranslate()

  const setCookies = () => {
    window?.Cookiebot?.show()
  }

  const linkStyle = isNewBrandingActive
    ? 'text-white underline text-xs hover:!text-peach-400'
    : 'text-brown-footer hover:!no-underline hover:text-white text-sm'

  return (
    <div className={` ${isNewBrandingActive ? '!text-white' : 'text-brown-footer hover:no-underline'}`}>
      <div className="mb-4 flex flex-wrap items-center tablet:flex-nowrap">
        <div className={`mb-8 mr-8 text-sm  tablet:mb-3`}>{t(`component.footer.companyName`)}</div>
        <ul className="m-0 flex w-full list-none flex-wrap items-center p-0 text-sm tablet:w-auto">
          <li className="mb-3 mr-4 tablet:mr-6">
            <Markdown className={linkStyle}>{t(`component.footer.terms`)}</Markdown>
          </li>
          <li className="mb-3 mr-4 tablet:mr-6">
            <Markdown className={linkStyle}>{t(`component.footer.cookie`)}</Markdown>
          </li>
          <li className="mb-3 mr-4 tablet:mr-6">
            <Markdown className={linkStyle}>{t(`component.footer.gdpr`)}</Markdown>
          </li>
          <li className="mb-3 ">
            <RadixButton
              variant="inline"
              onClick={setCookies}
              className={`font-normal normal-case underline-offset-auto hover:!no-underline focus:shadow-none`}
            >
              <Markdown className={linkStyle}>{t(`component.footer.cookieSettings`)}</Markdown>
            </RadixButton>
          </li>
        </ul>
      </div>
      <div className="mb-8 text-xs">
        <Markdown>{t(`component.footer.text`)}</Markdown>
      </div>
    </div>
  )
}

export default FooterInformation
