module.exports.routeNames = [
  { locale: 'cs', path: 'testy' },
  { locale: 'pl', path: 'testy' },
  { locale: 'uk', path: '%D1%82%D0%B5%D1%81%D1%82%D0%B8' }, // тести
  { locale: 'hu', path: 'tesztek' },
  { locale: 'sl', path: 'testi' },
  { locale: 'ro', path: 'teste' },
  { locale: 'el', path: '%CF%84%CE%B5%CF%83%CF%84' }, // τεστ
  { locale: 'sk', path: 'testy' },
  { locale: 'lt', path: 'testai' },
  { locale: 'en', path: 'tests' },
]
