import Image from 'next/image'
import React from 'react'
import { getTranslate } from '@/src/utilities/i18n'
import Icon from '@/src/components/icons/Icon'
import { RadixButtonLink } from '@/src/rebrand/buttonRadix'
import { cn } from '@/src/rebrand/utilities'
import CustomerSupportDot from '../pageBlocks/components/CustomerSupportDot'
import FreshdeskOpenButton from '../freshdesk/FreshdeskOpenButton'

const HeaderSupportSection: React.FC<{ className?: string; displayPhone: boolean }> = ({ className, displayPhone }) => {
  const { t } = getTranslate()

  return (
    <div className="mt-8 laptop:mt-12">
      <hr className="m-0 hidden border-[2px] border-sand-500 laptop:block" />
      <div className={cn(`flex flex-col items-center justify-between py-8 laptop:flex-row laptop:pb-0`, className)}>
        <div className="flex items-center pb-6 laptop:mr-2 laptop:w-1/2 laptop:pb-0">
          <div className="relative">
            <Image
              sizes="84px"
              height={84}
              width={76}
              alt={`support`}
              src={'/images/header/support-person.png'}
              className="mr-6"
            />
            <CustomerSupportDot className="bottom-0 right-4" />
          </div>
          <h3 className="text-xl font-semibold leading-relaxed text-secondary laptop:mb-0">
            {t('component.navigation.helpTitle')}
          </h3>
        </div>
        <div className="flex w-full flex-col items-center laptop:w-auto laptop:flex-row">
          <FreshdeskOpenButton
            variant="light"
            size="medium"
            className="mb-3 w-full text-nowrap laptop:mb-0 laptop:ml-3"
          >
            <Icon icon="talk" size="md" className="mr-1" />
            {t('components.pageBlocks.customerSupport.chat')}
            <Icon icon="arrow" color="primary" size="xs" className="ml-1 -rotate-45" />
          </FreshdeskOpenButton>
          {displayPhone && (
            <RadixButtonLink
              href={`tel:${process.env.NEXT_PUBLIC_SUPPORT_NUMBER}`}
              variant="light"
              size="medium"
              className="mb-3 w-full text-nowrap laptop:mb-0 laptop:ml-3"
            >
              <Icon icon="phone-rebrand" size="md" className="mr-1" />
              {process.env.NEXT_PUBLIC_SUPPORT_NUMBER}
              <Icon icon="arrow" color="primary" size="xs" className="ml-1 -rotate-45" />
            </RadixButtonLink>
          )}
          <RadixButtonLink
            href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}
            variant="light"
            size="medium"
            className="mb-3 w-full text-nowrap laptop:mb-0 laptop:ml-3"
          >
            <Icon icon="email" size="md" className="mr-1" />
            {t('components.pageBlocks.customerSupport.email')}
            <Icon icon="arrow" color="primary" size="xs" className="ml-1 -rotate-45" />
          </RadixButtonLink>
        </div>
      </div>
    </div>
  )
}

export default HeaderSupportSection
