'use client'
import { TZ } from '@/src/constants/date.constants'
import { cn } from '@/src/rebrand/utilities'
import dayjs from 'dayjs'
import React from 'react'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

type Props = {
  className?: string
}

const isSupportOnline = () => {
  const isWeekday = ![0, 6].includes(dayjs().tz(TZ).day())
  const correctHours = dayjs().tz(TZ).hour() >= 8 && dayjs().tz(TZ).hour() < 20
  return isWeekday && correctHours
}

const CustomerSupportDot: React.FC<Props> = ({ className }) => {
  const online = isSupportOnline()
  if (!online) return null

  return (
    <div className={cn('absolute z-30 max-w-max rounded-full bg-white p-[6px]', className)}>
      <div className="relative h-[14px] w-[14px] rounded-full bg-secondary">
        <div className="absolute left-[1px] top-[1px] z-20 h-[12px] w-[12px] rounded-full bg-secondary blur-sm" />
      </div>
    </div>
  )
}

export default CustomerSupportDot
