'use client'

import React from 'react'
import { ToastContainer } from 'react-toastify'

const ToastProvider: React.FC = () => {
  return <ToastContainer autoClose={5000} theme="colored" />
}

export default ToastProvider
