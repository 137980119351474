module.exports.routeNames = [
  { locale: 'cs', path: 'vypis-kategorii' },
  { locale: 'pl', path: 'baza-wiedzy' },
  {
    locale: 'uk',
    path: '%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D0%BD%D1%96-%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8',
  }, // корисні-матеріали
  { locale: 'hu', path: 'kategoriak' },
  { locale: 'sl', path: 'seznam-clankov' },
  { locale: 'lt', path: 'kategorijos' },
  { locale: 'ro', path: 'categorie' },
  { locale: 'el', path: '%CE%BA%CE%B1%CF%84%CE%B7%CE%B3%CE%BF%CF%81%CE%AF%CE%B5%CF%82' }, // κατηγορίες
  { locale: 'sk', path: 'vypis-kategorii' },
  { locale: 'en', path: 'category-list' },
]
