'use client'

import React from 'react'
import { languages } from '@/src/utilities/languagesConfig'
import { Sheet, SheetContent, SheetTrigger } from '@/src/components/header/sheetRadix'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/src/components/header/navigationRadix'
import NavigationIterator from '@/src/components/header/NavigationIterator'
import Icon from '@/src/components/icons/Icon'
import HeaderSupportSection from '@/src/components/header/HeaderSupportSection'
import { LoginButton, MenuButton, FindYourMatchButton } from '@/src/components/header/MenuComponents'
import { usePathname } from 'next/navigation'
import { HeaderProps } from './HeaderPublic'

const LanguagePicker = () => {
  const languagesList = Object.keys(languages)
  const currentLanguage = languagesList.find(
    (item) => languages[item] === `.${process.env.NEXT_PUBLIC_COUNTRY_IDENTIFIER}`,
  )
  return (
    <NavigationMenuItem className="w-full list-none tablet:w-auto">
      <MenuButton>
        <NavigationMenuTrigger>
          <Icon icon="internet" className="mr-3" size="sm" />
          {currentLanguage}
        </NavigationMenuTrigger>
      </MenuButton>
      <NavigationMenuContent>
        <div className="block p-0">
          {languagesList
            .filter((item) => item !== currentLanguage)
            .map((language) => {
              const text = language
              const domain = languages[language] || '.com'
              return (
                <NavigationMenuLink key={language} asChild className="py-3">
                  <MenuButton href={`https://hedepy${domain}/`} className="flex flex-row justify-start">
                    <img fetchPriority="high" src={`/images/flags/flag${domain}.svg`} alt={text} className="mr-4" />
                    <div className="">{text}</div>
                  </MenuButton>
                </NavigationMenuLink>
              )
            })}
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  )
}

const MenuMobile: React.FC<HeaderProps> = ({ menu, otherMenu, services, displayPhone }) => {
  const pathname = usePathname()

  const renderHamburgerLine = (index: number) => (
    <div key={index} className="h-[2px] w-[22px] rounded-[20px] bg-white"></div>
  )

  return (
    <>
      <LoginButton size="small" />
      <Sheet key={pathname}>
        <SheetTrigger className="" asChild>
          <RadixButton size="small" className="h-[44px] w-[44px] px-3">
            <div className="flex flex-col gap-1">{[...Array(3)].map((_, index) => renderHamburgerLine(index))}</div>
          </RadixButton>
        </SheetTrigger>
        <SheetContent side="right" className="h-full w-full overflow-y-auto bg-white tablet:rounded-b-2xl">
          <NavigationMenu orientation="vertical">
            <NavigationMenuList className="w-full flex-col items-start space-x-0 pl-0 pt-12">
              <NavigationIterator
                key="navigation-iterator"
                services={services}
                menu={menu}
                isMobile
                displayPhone={displayPhone}
                otherMenu={otherMenu}
              />
              <LanguagePicker key="language-picker" />
            </NavigationMenuList>
          </NavigationMenu>
          <FindYourMatchButton size="medium" className="mt-6 w-full" />
          <HeaderSupportSection className="block p-0 laptop:hidden" displayPhone={displayPhone} />
        </SheetContent>
      </Sheet>
    </>
  )
}

export default MenuMobile
